import { BaseService } from '@/api/request';
const api = {};
// NaN undefined null过滤处理
// 注意，尽量不要过滤空字符串
const filterNull = json => {
  let newJson = {};
  let reg = RegExp(/NaN|undefined|null/);
  for (let li in json) {
    if (json[li] && !reg.test(json[li])) {
      newJson[li] = json[li];
    }
  }
  return newJson;
};
/**
 * @description: 获取项目进度的数据
 */
api.getList = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/schedules', filterNull(params));
};
api.getRecentSchedule = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/schedules/last', filterNull(params));
};
api.batchSaveSchedule = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/schedules', filterNull(params));
};
// 确认提交接口
api.saveSchedule = function (params = {}) {
  return BaseService.postRequest('/api/pmf/v1/schedules/commit', filterNull(params));
};

// 校验是否能够提交项目进度(2023-04-07-new)
api.identitySubmitSchedule = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/schedules/validateCommit', filterNull(params));
};

/**
 * @description 获取合同名称
 */
api.getContractNames = function (params = {}) {
  return BaseService.getRequest('/api/pmf/v1/servings/contractCodes', filterNull(params));
};
export default api;
