import { BaseService } from '@/api/request';

const api = {};
/**
 * @description: 获取运营人员可管理的项目列表
 * @param  {*page 页码}
 * @param  {*pagesize 页面大小}
 */
api.getOperateProject = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/project/getProjectByOperation', params);
};

api.endProject = function (params = {}, id) {
  return BaseService.postRequest('/api/pmf/v1/projects/' + id + '/finish', params);
};

api.resetProject = function (params = {}, id) {
  return BaseService.postRequest('/api/pmf/v1/projects/' + id + '/restart', params);
};
api.deleteProject = function (id) {
  return BaseService.delRequest('/api/pmf/v1/projects/' + id);
};
export default api;
