<template>
  <div class="editRowContainer">
    <div class="mainContainer">
      <p class="left">{{ $t(leftHeader) }}</p>
      <p class="right" @click="addItem"><i class="el-icon-circle-plus-outline addIcon"></i>{{ $t(rightHeader) }}</p>
    </div>
    <div class="addContainer">
      <div class="description">
        <p class="descCode">{{ $t('projectManagement.projectCode') }}</p>
        <p class="descName">{{ $t('contractManagement.name') }}</p>
      </div>
      <div class="operatorContainer" v-for="(val, idx) in formGroupData" v-show="!val.isDeleted" :key="idx">
        <div class="typeContainer" v-for="(typeVal, typeIdx) in groupData" :key="typeIdx">
          <div class="selectCol" v-if="typeVal.type === 'select' && typeIdx === typeVal.position">
            <el-select v-model="formGroupData[idx][typeVal.label]" @change="emitData" filterable placeholder="请选择" :clearable="false">
              <el-option
                v-for="item in typeVal.optionGroup"
                :key="item.id ? item.id : item"
                :label="item.name ? item.name : item"
                :value="item.id ? item.id : item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="inputCol" v-if="typeVal.type === 'input' && typeIdx === typeVal.position">
            <el-input
              type="textarea"
              @change="emitData"
              :rows="3"
              placeholder="请输入"
              size="mini"
              v-model="formGroupData[idx][typeVal.label]"
              clearable
            >
            </el-input>
          </div>
        </div>
        <!--        <div class="role">
          <el-select v-model="groupData[idx].role" filterable placeholder="请选择" :clearable="false">
            <el-option v-for="item in optionGroup.optionGroup" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div> -->
        <div class="removeOperation">
          <i class="el-icon-remove-outline removeOp" @click="deleteItem(idx)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditRowContainer',
  props: {
    leftHeader: {
      type: String,
      default: '',
    },
    rightHeader: {
      type: String,
      default: '',
    },
    /* 数组对象之中，必传：label,type,valueType,position */
    fillDataArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
    contractNames: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    dialogContractVisible: {
      type: Boolean,
      default: false,
    },
    // 用于标记删除法的关键参数
    keyParam: {
      type: String,
      default: 'id',
    },
  },
  watch: {
    dialogContractVisible: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) {
          this.formGroupData = [];
        } else {
          this.formGroupData = [...this.contractNames];
        }
      },
    },
  },
  computed: {
    groupData() {
      let temArrData = [];
      this.fillDataArr.map(item => {
        const temObjData = {};
        // 值的名称
        temObjData.label = item.label;
        // 对应选择器类型
        temObjData.type = item.type;
        // 值的类型
        temObjData.valueType = item.valueType;
        // 对应在容器中排序位置
        temObjData.position = item.position;
        // 值的获取数据源option
        temObjData.optionGroup = item.optionGroup || [];
        // 值的class选取，可以在当前组件中定义，然后直接给class(后期改成style)
        temObjData.styleClass = item.styleClass || '';
        temArrData.push(temObjData);
      });

      return temArrData;
    },
  },
  data() {
    return {
      formGroupData: [],
    };
  },
  methods: {
    addItem() {
      const labelGroup = {};
      this.fillDataArr.map(item => {
        labelGroup[item.label] = item.valueType;
      });

      labelGroup.isDeleted = false;

      this.formGroupData.push(labelGroup);
      this.$emit('emitContractData', this.formGroupData);
    },
    // 后续如果需要接入编辑已有数据，要处理一下显示和实际删除的逻辑
    deleteItem(idx) {
      if (this.isDeleted && this.formGroupData[idx][this.keyParam]) {
        this.formGroupData[idx].isDeleted = true;
      } else {
        this.formGroupData.splice(idx, 1);
      }

      this.$emit('emitContractData', this.formGroupData);
    },
    emitData() {
      this.$emit('emitContractData', this.formGroupData);
    },
  },
};
</script>

<style lang="scss" scoped>
.editRowContainer {
  margin: 0;
  .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      font-weight: 600;
    }
    .right {
      cursor: pointer;
      .addIcon {
        color: #1890ff;
        margin-right: 4px;
        font-size: 22px;
        line-height: 28px;
        vertical-align: middle;
      }
    }
  }
  .addContainer {
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    height: 260px;
    background: #f8f8f9;
    border-radius: 4px;
    overflow-y: auto;
    .description {
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      font-weight: 400;
      color: #515a6e;
    }
    .operatorContainer {
      padding: 6px 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;

      .removeOp {
        cursor: pointer;
        color: #1890ff;
        font-size: 22px;
        margin-right: 0 auto;
      }
    }
    .selectCol,
    .descCode {
      text-align: left;
      width: 184px;
    }

    .inputCol {
      text-align: left;
      width: 480px;
    }
    .descName {
      text-align: left;
      width: 510px;
    }
  }
}
</style>
