export const mixinsProjectStatement = {
  computed: {
    isWorkItem() {
      return this.$route.meta.name === '项目管理' && this.projectUpdate && this.typeHeaderModule === this.$t('projectManagement.editWorkItem');
    },
    isWorkShowItem() {
      return this.$route.meta.name === '项目管理' && this.typeHeaderModule === this.$t('projectManagement.editWorkItem');
    },
    isServiceItem() {
      return this.$route.meta.name === '项目管理' && this.projectUpdate && this.typeHeaderModule === this.$t('projectManagement.editServiceItem');
    },
    rowEditServiceItem() {
      return (
        this.$route.meta.name === '项目管理' && this.editServiceItemAuth && this.typeHeaderModule === this.$t('projectManagement.editServiceItem')
      );
    },
    rowAddServiceItem() {
      return (
        this.$route.meta.name === '项目管理' && this.addServiceItemAuth && this.typeHeaderModule === this.$t('projectManagement.editServiceItem')
      );
    },
    rowEditJobItem() {
      return this.$route.meta.name === '项目管理' && this.taskRead && this.typeHeaderModule === this.$t('projectManagement.editWorkItem');
    },
    isRequired() {
      const types = ['DM', 'STAT', 'PK'];
      return types.includes(this.ruleForm.category);
    },
    editServiceItemAuth() {
      return this.$store.state.app.userConfig.permissionsKeys?.ProjectManagementUpdateService;
    },
    addServiceItemAuth() {
      return this.$store.state.app.userConfig.permissionsKeys?.ProjectManagementCreateService;
    },
    projectRead() {
      return this.$store.state.app.userConfig.permissionsKeys?.ProjectManagementRead;
    },
    operationRead() {
      return this.$store.state.app.userConfig.permissionsKeys?.OperationManagementRead;
    },
    operationUpdate() {
      return this.$store.state.app.userConfig.permissionsKeys?.OperationManagementUpdate;
    },
    operationCreate() {
      return this.$store.state.app.userConfig.permissionsKeys?.OperationManagementCreate;
    },
    taskRead() {
      return this.$store.state.app.userConfig.permissionsKeys?.JobManagementRead;
    },
    operationDelete() {
      return this.$store.state.app.userConfig.permissionsKeys?.OperationManagementDelete;
    },
    operationExport() {
      return this.$store.state.app.userConfig.permissionsKeys?.OperationManagementExport;
    },
    OperationRestart() {
      return this.$store.state.app.userConfig.permissionsKeys?.OperationManagementRestart;
    },
    OperationEnd() {
      return this.$store.state.app.userConfig.permissionsKeys?.OperationManagementFinish;
    },
    projectUpdate() {
      return this.$store.state.app.userConfig.permissionsKeys?.ProjectManagementUpdate;
    },
    disabledUpdate() {
      return (
        (this.routeName === this.$t('route.ProjectManagement') && !this.projectUpdate) || (this.routeName === '运营管理' && !this.operationUpdate)
      );
    },
    workRead() {
      return this.$store.state.app.userConfig.permissionsKeys?.TaskManagementRead;
    },
    workEdit() {
      return this.$store.state.app.userConfig.permissionsKeys?.TaskManagementUpdate;
    },
    workCreate() {
      return this.$store.state.app.userConfig.permissionsKeys?.TaskManagementCreate;
    },
    workDelete() {
      return this.$store.state.app.userConfig.permissionsKeys?.TaskManagementDelete;
    },
    standardServiceRead() {
      return this.$store.state.app.userConfig.permissionsKeys?.TaskManagementReadStandardService;
    },
    routeName() {
      return this.$route.meta.name;
    },
    /* 项目进度 */
    scheduleRead() {
      return this.$store.state.app.userConfig.permissionsKeys?.ScheduleManagementRead;
    },
    scheduleSave() {
      return this.$store.state.app.userConfig.permissionsKeys?.ScheduleManagementSave;
    },
    scheduleForceSave() {
      return this.$store.state.app.userConfig.permissionsKeys?.ScheduleManagementForceSave;
    },
    scheduleManagementCommit() {
      return this.$store.state.app.userConfig.permissionsKeys?.ScheduleManagementCommit;
    },
  },
};
